// .cache/gatsby-mdx/wrapper-components/{wrapper-filepath-hash}-{scope-hash}.js
import React from 'react';
import { MDXScopeProvider } from 'gatsby-mdx/context';

import __mdxScope_0 from '/opt/build/repo/.cache/gatsby-mdx/mdx-scopes-dir/3010b3badc54a9dfa4a4c80e419a41b2.js';

import OriginalWrapper from '/opt/build/repo/src/templates/project.jsx';

import { graphql } from 'gatsby';

// pageQuery, etc get hoisted to here
export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      code {
        body
      }
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        client
        color
        service
        cover {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 800) {
              src
            }
          }
        }
      }
    }
  }
`;;

export default ({children, ...props}) => <MDXScopeProvider __mdxScope={{...__mdxScope_0}}>
  <OriginalWrapper
    {...props}
  >
    {children}
  </OriginalWrapper>
</MDXScopeProvider>